@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .sidebar-icon {
    @apply relative flex items-center justify-center h-12 w-12 md:h-10 md:w-10 mt-2 mb-2 mx-auto shadow-lg bg-white text-gray-700 hover:bg-gray-700 hover:text-white rounded-3xl hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer hover:animate-text hover:bg-gradient-to-r from-pink-400 via-blue-500 to-teal-500;
  }

  .sidebar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md text-white bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left ;
  }

  .content-container {
    @apply flex flex-col 
    bg-gray-100 dark:bg-gray-700 bg-opacity-20
    /* ml-20   */
    h-full min-h-full
    overflow-hidden
    mt-9; 
  }

  .content-list {
    @apply flex flex-col items-center 
    /* h-full w-full  */
    mt-0 ml-0 mx-auto px-0 pb-12 
    overflow-y-scroll;
  }

  .content-wrapper {
    @apply font-mono  mx-auto px-5 w-fit text-gray-700 dark:text-white text-left justify-evenly ;
  }

  .content-wrapper-odd {
    @apply font-mono  mx-auto px-5 w-fit text-gray-700 dark:text-white text-left justify-evenly;
  }


  .top-navigation {
    @apply flex flex-row items-center justify-start
    bg-teal-300 bg-opacity-80 dark:bg-accent dark:bg-opacity-75
    w-screen h-16 
    sticky top-0 
    pl-5 border-hidden md:pl-24;
    
  }

  .top-navigation-icon {
    @apply text-gray-700
    mr-3 ml-4
    transition duration-300 ease-in-out 
    hover:text-pink-400 
    hover:animate-bounce
    cursor-pointer;
  }

  .top-navigation-icon:first {
    @apply ml-auto mr-4;
  }

  .title-hashtag {
    @apply md:text-xl text-sm tracking-wider  text-gray-700
    ml-12 my-auto md:ml-20 ;
  }

  .title-text {
    @apply md:text-xl text-sm md:font-bold text-gray-700 tracking-wider 
    mr-auto ml-8 md:ml-16 my-auto  
    transition duration-300 ease-in-out font-mono;
  }

  .search {
    @apply w-1/5 flex items-center justify-start 
    bg-gray-400 dark:bg-gray-600
    text-gray-500
    px-2 h-9 ml-0 mr-0 
    rounded-md shadow-md
    transition duration-300 ease-in-out;
  }

  .search-input {
    @apply w-full font-mono font-semibold
    bg-transparent outline-none 
    text-gray-500  placeholder-gray-500
    pl-1 rounded;
  }

  .vertical-line  {
    @apply border-double border-l-4 border-gray-600 h-full left-32 md:left-96 md:pl-10 absolute mt-14;
  }

  .exp-img {
    @apply  w-12 h-12 -ml-3 mt-3 md:mr-40 md:ml-32 md:mt-4 md:w-20 z-10;
  }

  .job-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-20 rounded-md shadow-md text-white bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left ;
  }
  
  .description-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-48 rounded-md shadow-md text-white bg-gray-600 text-xs font-bold transition-all duration-100 scale-0 origin-left md:ml-80 ;
  }

  .degree-border { 
    @apply border border-transparent px-4 py-4 mb-16 md:ml-52 md:mt-10 rounded-xl;
  }

  .footer {
    @apply pb-1 pr-2 text-right bg-gray-300 bg-opacity-30
     /* ml-20 md:ml-20  */
     md:pr-10 justify-evenly;
  }

  .sidebar-hr {
    @apply w-52 text-center justify-center ml-96 pl-96 mt-2 
  }

  .mockup-code {
      @apply min-w-[18rem] bg-neutral py-5 text-neutral-content rounded-box;

  }
  .mockup-code::before{
    content: "";
    @apply mb-4 block h-3 w-3 rounded-full opacity-40;
    box-shadow: 1.4em 0 red, 2.8em 0 yellow, 4.2em 0 green ;
}
  .beauty-text {
    /* font-family: "Brush Script MT", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; */
    @apply font-mono font-normal tracking-tight;
  }

  .blue__gradient {
    background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
    filter: blur(123px);
  }
  
  .pink__gradient {
    background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
    filter: blur(900px);
  }
  
  .white__gradient {
    background: rgba(255, 255, 255, 0.6);
    filter: blur(750px);
  }

  /* .logo-style img {
    margin-right: 10px;
    width: 10%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
  } */
  
}

